import React from "react"
import {Helmet} from "react-helmet"

export default function about(props) {
    return (
        <>
            <Helmet >
                <title>Nosotros | Pentcloud</title>
            </Helmet>
            <div className="mb-24 lg:mb-48 md:article-header px-12 lg:px-0 h-96 bg-gray-200 items-center flex">
                <div className="container mx-auto">
                    <h1 className="font-extrabold text-5xl text-gray">
                        Nosotros
                    </h1>
                </div>
            </div>
            <div className="mb-24 lg:mb-48 px-12">
				<div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                        <div className="lg:col-span-1 col-span-2 lg:mb-24 mb-12">
                            <h2 className="font-extrabold text-4xl text-gray leading-snug mb-12">
                                ipsum quia dolor sit amet, consectetur adipisci velit.
                            </h2>
                            <span className="text-gray-400 text-xl mb-12">
                                Excespanteur sint occaecat cupidatat non proident sunt iculpa qui officia deserunt mollit anim est. laborum sed perspiciatis unde omnis natus error sit voluptatem accusantium dolore mque laudantium totam rem aperiam.
                            </span>
                            <div className="flex flex-row flex-wrap justify-betweeen">
                                <div className="items-center flex flex-row mr-12">
                                    <h3 className="text-gray font-bold text-4xl mr-8">
                                        5K
                                    </h3>
                                    <span className="text-xl text-blue-500">
                                        Clientes Satisfechos
                                    </span> 
                                </div>
                                <div className="items-center flex flex-row mr-12">
                                    <h3 className="text-gray font-bold text-4xl mr-8">
                                        5K
                                    </h3>
                                    <span className="text-xl text-blue-500">
                                        Clientes Satisfechos
                                    </span>
                                </div>
                                <div className="items-center flex flex-row">
                                    <h3 className="text-gray font-bold text-4xl mr-8">
                                        5K
                                    </h3>
                                    <span className="text-xl text-blue-500">
                                        Clientes Satisfechos
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 bg-blue rounded-xl shadow-xl lg:mb-24 mb-12">
                            
                        </div>
                        <div className="col-span-2">
                            <h2 className="font-extrabold text-4xl text-gray leading-snug mb-12 lg:w-1/2 w-full">
                                Productivo y obtenga una solución más rápida
                            </h2>
                        </div>
                        <div className="col-span-1 bg-blue rounded-xl shadow-xl lg:mb-24 mb-12">
                            
                        </div>
                        <div className="col-span-1 flex flex-row flex-wrap">
                            <div className="w-1/2 pr-12 mb-12">
                                <img src="/images/wallet.svg" alt=""/>
                                <h3 className="text-gray text-xl font-bold">
                                    Título
                                </h3>
                                <p className="text-gray-400 text-xl">
                                    Excepteur sint occaecat cupidatat non
                                </p>
                            </div>
                            <div className="w-1/2 pr-12 mb-12">
                                <img src="/images/wallet.svg" alt=""/>
                                <h3 className="text-gray text-xl font-bold">
                                    Título
                                </h3>
                                <p className="text-gray-400 text-xl">
                                    Excepteur sint occaecat cupidatat non
                                </p>
                            </div>
                            <div className="w-1/2 pr-12">
                                <img src="/images/wallet.svg" alt=""/>
                                <h3 className="text-gray text-xl font-bold">
                                    Título
                                </h3>
                                <p className="text-gray-400 text-xl">
                                    Excepteur sint occaecat cupidatat non
                                </p>
                            </div>
                            <div className="w-1/2 pr-12">
                                <img src="/images/wallet.svg" alt=""/>
                                <h3 className="text-gray text-xl font-bold">
                                    Título
                                </h3>
                                <p className="text-gray-400 text-xl">
                                    Excepteur sint occaecat cupidatat non
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-24 lg:mb-24">
                <div className="container mx-auto text-center lg:px-48 px-12">
                    <h1 className="font-bold text-4xl text-gray mb-8">
                        ¿Qué dicen sobre nosotros?
                    </h1>
                    <p className="text-gray-400 text-xl mb-12">
                        Excepteur sint occaecat cupidatat non proident sunt iculpa qui officia deserunt mollit anim est. laborum sed perspiciatis unde omnis natus error sit voluptatem accusantium dolore mque laudantium totam rem aperiam.
                    </p>
                </div>
            </div>
            <div className="container mx-auto grid lg:grid-cols-2 grid-cols-1 px-12 gap-12 justify-center">
                <div className="flex flex-col">
                        <p className="text-gray-400 text-xl mb-8 italic">
                            Excepteur sint occaecat cupidatat non proident sunt iculpa qui officia deserunt mollit anim est. laborum sed perspiciatis unde omnis natus error sit voluptatem accusantium dolore mque laudantium totam rem aperiam.
                        </p>
                        <div className="flex flex-row item-center">
                            <div className="w-12 h-12 rounded-full bg-blue mr-4"></div>
                            <div className="flex flex-col">
                                <h4 className="text-gray-500 text-xl font-bold">David McNail</h4>
                                <span className="text-gray-400 text-xl">RRFG CEO</span>
                            </div>
                        </div>
                    </div>
                <div className="flex flex-col">
                    <p className="text-gray-400 text-xl mb-8 italic">
                        Excepteur sint occaecat cupidatat non proident sunt iculpa qui officia deserunt mollit anim est. laborum sed perspiciatis unde omnis natus error sit voluptatem accusantium dolore mque laudantium totam rem aperiam.
                    </p>
                    <div className="flex flex-row item-center">
                        <div className="w-12 h-12 rounded-full bg-blue mr-4"></div>
                        <div className="flex flex-col">
                            <h4 className="text-gray-500 text-xl font-bold">David McNail</h4>
                            <span className="text-gray-400 text-xl">RRFG CEO</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}